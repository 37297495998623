.uppercase {
  text-transform: uppercase;
}

.semibold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.section-container {
  padding: 100px 8% 0 8%;

  .section-container-inner {
    max-width: 1200px;
  }
}
