.social-media-icon {
  width: 30px !important;
  height: 30px !important;
}

.social-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px 0;

  .social-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
}
