.resume-item-container {
  margin: 5px 0;
}

.circle-border {
  width: 20px;
  height: 20px;
  background-color: #0072b1; // linkedIn blue
  border-radius: 50%;
}

.resume-items {
  margin-top: 40px;
}

.resume-item-outerbox {
  display: flex;
}

.resume-item-left {
  flex: 1;
  margin-right: 20px;
}

.resume-item-right {
  flex: 3;
  margin-left: 4px;
  padding-left: 20px;
  color: #4d4d4d;
}

.resume-item-years-company {
  margin-bottom: 5px;
}

.resume-item-years {
  text-align: right;
  font-size: 20px;
}

.resume-item-company {
  font-size: 20px;
}

.resume-item-role-responsibilities {
  border-left: 2px solid #171717;
  margin-left: 9px;
  padding-left: 35px;
  padding-bottom: 10px;

  .resume-item-role {
    font-style: italic;
  }

  .resume-item-responsibility {
    margin: 5px 0;
    font-size: 14px;
  }
}

// iphone 13 pro max is 428px
@media screen and (max-width: 560px) {
  .resume-item-years, .resume-item-company {
    font-size: 16px;
  }
}
