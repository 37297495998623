.yoga-dance-title {
  padding: 100px 8% 25px 8%;

  .yoga-dance-inner {
    max-width: 1200px;
  }
}

.section-yoga {
  background-image: url(../img/yoga-background.jpeg);
  width: 100%;
  height: 80vh;
  min-height: 430px;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
}

.yoga-info {
  margin-left: 8%;
  width: 420px;
  background-color: rgba(255, 255, 255, 0.5);

  .yoga-info-inner {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
  }

  .ytt-cert {
    max-width: 100%;
  }
}

.section-dance-video {
  text-align: center;
  padding: 40px 0px;
  background-color: black;

  .competition-video-youtube {
    width: 560px;
    height: 315px;
  }
}

.pole-intro {
  text-align: center;
  color: white;
  background-color: black;
  padding: 30px 0;
  font-size: 18px;
}

.pole-comp-items {
  display: flex;
  height: 67vh;

  .pole-comp-item {
    flex: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &.pole-comp-item-hovered {
      cursor: pointer;

      .pole-comp-title {
        background-color: transparent;
      }

      .first-place {
        background-color: transparent !important;
      }
    }

    .pole-comp-title {
      color: white;
      margin: 0 5px 20px;
      padding: 8px 12px;
      background-color: rgba(225, 225, 225, 0.4);
    }

    .pole-comp-medal {
      align-self: flex-start;
      width: 50px;
      height: 50px;
      margin: 15px;
    }

    .pole-comp-item-hovered-modal {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(225, 225, 225, 0.4);
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      &.dark-overlay {
        background-color: rgba(0, 0, 0, 0.4);
      }

      .pole-comp-item-watch {
        font-size: 26px;
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .section-yoga {
    background-position: center;
  }
}


// iphone 13 pro max is 428px
@media screen and (max-width: 560px) {
  .yoga-info {
    .yoga-info-inner {
      justify-content: space-between;
    }
  }

  .section-dance-video {
    .competition-video-youtube {
      width: 350px;
      height: 200px;
    }
  }

  .pole-comp-items {
    flex-direction: column;
    height: auto;

    .pole-comp-item {
      min-height: 300px;
    }
  }
}

// mobile devices
@media only screen and (hover: none) and (pointer: coarse) {
  .section-yoga {
    background-attachment: scroll;
  }
}
