.writing-sections {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.writing-section {
  flex: 1;
  padding: 20px 15px;

  .writing-text {
    .writing-section-title {

    }

    .writing-section-description {
      font-size: 12px;
    }
  }

  &.writing-section-hovered {
    cursor: pointer;
    background-color: #e6e6e6;
  }

  &.writing-section-active {
    background-color: #171717;
    color: white;
    font-weight: 500px;

    .writing-text {
      .writing-section-title {
        font-weight: bold;
      }
    }
  }
}

.writing-articles {
  background-color: #171717;
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;

  .writing-article {
    background: white;
    flex: 1 1 30%;
    margin: 8px 5px;
    min-height: 300px;
    min-width: 250px;

    &.writing-article-hovered {
      cursor: pointer;
    }
  }

  .writing-article-link {
    text-decoration: none;
    color: black;
  }

  .article-image {
    height: 170px;
    background-size: cover;
  }

  .article-text {
    padding: 0 12px;
    .article-description {
      font-size: 12px;
      color: #1a1a1a;
    }
  }
}

@media screen and (max-width: 560px) {
  .writing {
    padding: 100px 0 0 !important;

    .writing-section-header {
      padding-left: 8%;
    }
  }
}
