.above-the-fold {
  width: 100%;
  height: 100vh;
}

.header-img {
  background-image: url(../img/headshot-compressed.jpeg);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: relative;
}

.top-nav-menu {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 75px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.nav-name {
  letter-spacing: 4px;
}

.menu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .menu-item {
    margin: 0 10px;
    font-size: 12px;
    color: #171717;
    text-decoration: none;

    &:hover {
      color: rgba(26, 26, 26, 0.7);
    }
  }
}

.intro-blurbs {
  color: white;
  margin: 0 80px;
  position: absolute;
  top: 150px;

  .intro-blurb-container {
    display: flex;
  }

  .intro-blurb {
    background-color: #171717;
    padding: 15px 20px;
    margin: 10px 0;
  }

  .blurb-name {
    font-size: 32px;
    letter-spacing: 4px;
  }
}

@media screen and (max-width: 920px) {
  .intro-blurbs {
    position: absolute;
    bottom: 85px;
    top: auto;
  }
}

// iphone 13 pro max is 428px
@media screen and (max-width: 560px) {
  .top-nav-menu {
    padding: 25px 30px;
  }

  .intro-blurbs {
    margin: 0 25px;
  }
}

// mobile devices
@media only screen and (hover: none) and (pointer: coarse) {
  .header-img {
    background-attachment: scroll;
  }
}
