.footer {
  background-color: #171717;
  padding: 80px 8%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-social-icons {
  display: flex;
  align-items: center;
}

.footer-social-icon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 10px;
}

.footer-quote {
  font-style: italic;
  font-size: 14px
}
